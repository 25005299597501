function createWorker(pWorkerPath = './dist/worker.js') {
    const worker = new Worker(pWorkerPath, {
        type: 'module'
    });

    return worker;
}

class O365Compiler {
    /** @type {Worker} */
    worker = null;
    activeOperations = new Map();
    constructor(pOptions) {
        this.worker = createWorker(pOptions?.workerPath);
        this.worker.onmessage = this.handleMessage.bind(this);
    }

    /**
     * @param {MessageEvent} pMessage 
     */
    handleMessage(pMessage) {
        if (pMessage.data[0] !== 'o365-compiler-worker') { return; }
        const operationInfo = pMessage.data[1];
        const promise = this.activeOperations.get(operationInfo.uid);
        if (operationInfo.success) {
            promise.res(pMessage.data[2]);
        } else {
            promise.rej(pMessage.data[2]);
        }
        this.activeOperations.delete(operationInfo.uid);
    }

    executeOperation(pOperation, pPayload) {
        const uid = window.crypto.randomUUID();
        let promiseRes = () => { };
        let promiseRej = () => { };
        const operationPromise = new Promise((res, rej) => {
            promiseRes = res;
            promiseRej = rej;
        });
        this.activeOperations.set(uid, {
            res: promiseRes,
            rej: promiseRej
        });
        this.worker.postMessage(['o365-compiler-worker', {
            uid: uid,
            operation: pOperation
        }, pPayload]);
        return operationPromise;
    }

    prettier(pScript, pFileName) {
        return this.executeOperation('prettier', {
            script: pScript,
            fileName: pFileName
        });
    }

    eslint(pScript, pFileName) {
        return this.executeOperation('eslint', {
            script: pScript,
            fileName: pFileName
        });
    }
    
    dts(pScript, pFileName) {
        return this.executeOperation('dts', {
            script: pScript,
            fileName: pFileName,
            url: this.resolveO365ImportUrl('typescript')
        });
        
    }

    /**
     * 
     * @param {string} pModule 
     * @returns {string}
     */
    resolveO365ImportUrl(pModule) {
        const importMapsScript = document.querySelector('script[type="importmap-shim"]') ??
            document.querySelector('script[type="importmap"]');
        if (!importMapsScript) { throw new Error('Failed to find importmaps on the page') };
        const importMap = JSON.parse(importMapsScript.innerHTML);

        return importMap.imports[pModule];
    }
}

export { createWorker, O365Compiler }